import React from 'react';
import { FaleConosco } from './FaleConosco';
import './Footer.scss';
import { FooterCurve } from './shapes/FooterCurve';

export const Footer = () => {
	return (
		<footer className="text-white-50">
			<FooterCurve />
			{/* <div className="footer-whatsapp-container">
				<a href={contatoMinhaSaude.whatsAppUrl} target="_blank" rel="noopener noreferrer">
					<img alt={'Whatsapp'} className="footer-whatsapp-img" src={Whatsapp} />
				</a>
			</div> */}
			<div className="bg-primary">
				<FaleConosco />
			</div>
		</footer>
	);
};
