import React, { ReactNode } from 'react';
import { Navbar } from './Navbar';
interface RouterContainerProps {
	children: ReactNode;
}

export const RouterContainer = ({ children }: RouterContainerProps) => (
	<>
		<Navbar />
		{children}
	</>
);
