import React from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import FormAdesao from './components/FormAdesao/FormAdesao';
import './styles.scss';

interface ModalFormAdesaoProps {
	isOpenFormAdesao: boolean;
	closeModal(): void;
}
const ModalFormAdesao = ({ isOpenFormAdesao, closeModal }: ModalFormAdesaoProps) => (
	<Modal show={isOpenFormAdesao} onHide={closeModal} centered backdrop="static">
		<Modal.Header closeButton>
			<Modal.Title className="text-center">Gestor Municipal, faça aqui sua adesão!</Modal.Title>
		</Modal.Header>
		<FormAdesao closeFormModal={closeModal} />
	</Modal>
);

export default ModalFormAdesao;
