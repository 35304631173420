import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { categorias } from '../constants/postagens';
import { Homepage } from './pages/Homepage';
import { Posts } from './pages/Posts';

export const Router = () => (
	<Switch>
		<Route exact path="/">
			<Homepage />
		</Route>
		{categorias.map((categoria) => (
			<Route key={categoria.id} path={`/${categoria.path}`}>
				<Posts categoria={categoria} />
			</Route>
		))}
		<Redirect to={'/'} />
	</Switch>
);
