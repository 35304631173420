import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { Categoria } from '../../constants/postagens';
import { usePosts } from '../../hooks/usePosts';
import { Post } from '../post/Post';

export const Posts = ({ categoria }: { categoria: Categoria }) => {
	const posts = usePosts({ categoria });

	if (!posts) {
		return <Loading />;
	} else {
		return (
			<main className="my-5">
				<Container>
					{posts.length > 0 ? (
						posts.map((post) => (
							<Row key={post.id} className="mb-5">
								<Col>
									<Post {...post} />
								</Col>
							</Row>
						))
					) : (
						<Empty />
					)}
				</Container>
			</main>
		);
	}
};

const Loading = () => (
	<main className="my-5">
		<Container>
			<Row className="mb-5">
				<Col className="d-flex align-items-center flex-column">
					<Spinner
						animation="border"
						variant="primary"
						style={{ width: '5em', height: '5em' }}
						className="mb-5"
					/>
					<h2 className="text-muted">Carregando postagens...</h2>
				</Col>
			</Row>
		</Container>
	</main>
);

const Empty = () => (
	<Row className="mb-5">
		<Col className="d-flex align-items-center flex-column">
			<FontAwesomeIcon className="text-muted mb-5" icon={faFileAlt} size="5x" />
			<h2 className="text-muted">Não há postagens</h2>
		</Col>
	</Row>
);
