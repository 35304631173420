import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Logo } from './shared/Logo';
export const Navbar = () => {
	// const { pathname } = useLocation();

	return (
		<nav className="navbar navbar-expand-lg px-4 pt-2 pb-2 navbar-dark bg-primary">
			<a className="navbar-brand" href="/">
				<Logo id="main-logo" />
			</a>
			{/* <button
				className="navbar-toggler"
				type="button"
				data-toggle="collapse"
				data-target="#navbarNav"
				aria-controls="navbarNav"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<span className="navbar-toggler-icon" />
			</button> */}
			<div className="collapse navbar-collapse" id="navbarNav">
				<ul className="navbar-nav flex-grow-1 justify-content-center mx-5">
					{/* <li key="home" className={`nav-item pr-1 ${pathname === '/' ? 'active' : ''}`}>
						<a className="nav-link" href="/">
							<small>Home</small>
						</a>
					</li>
					{categorias.map((categoria) => (
						<li
							key={categoria.id}
							className={`nav-item pr-1 ${pathname.includes(categoria.path) ? 'active' : ''}`}
						>
							<a className="nav-link" href={`/${categoria.path}`}>
								<small>{categoria.name}</small>
							</a>
						</li>
					))} */}
				</ul>
			</div>
			<div className="d-none d-lg-flex">
				<a
					className="nav-link p-0 d-flex align-items-center flex-column"
					target="_blank"
					rel="noopener noreferrer"
					href="https://bi.appminhasaude.com.br/"
				>
					<FontAwesomeIcon color="#fff" icon={faDesktop} size="1x" />
					<p className="text-light font-weight-bold link-text">Acesso Restrito</p>
				</a>
			</div>
		</nav>
	);
};
