import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { Categoria, WORDPRESS_URL } from '../constants/postagens';
import IPost from '../models/Post';

interface WPPost {
	id: number;
	date: string;
	title: { rendered: string };
	content: { rendered: string };
	author: number;
	better_featured_image?: {
		source_url: string;
	};
	_embedded: {
		author: [
			{
				id: number;
				name: string;
			},
		];
	};
}

type postsState = IPost[] | null;

export const usePosts = ({ categoria }: { categoria: Categoria }) => {
	const [posts, setPosts] = useState<postsState>(null);
	useEffect(() => {
		(async () => {
			const resp = await axios.get<WPPost[]>(`${WORDPRESS_URL}?per_page=100&categories=${categoria.id}&_embed`);
			const posts: IPost[] = resp.data.map((post) => ({
				id: post.id,
				title: post.title.rendered,
				text: post.content.rendered,
				...(post.better_featured_image ? { image: post.better_featured_image.source_url } : {}),
				date: format(parseISO(post.date), "dd 'de' MMMM 'de' yyyy", { locale: ptBR }),
				// author: post._embedded.author.find((author) => author.id === post.author)?.name || undefined,
			}));
			setPosts(posts);
		})();
	}, [categoria]);

	return posts;
};
