import React from 'react';
import ReactGA from 'react-ga';
import { contatoMinhaSaude } from '../models/Contato';
import './FaleConosco.scss';
import { Formulario } from './form/Formulario';
import { DownloadAppButtons } from './header/DownloadAppButtons';
import { Logo } from './shared/Logo';

export const FaleConosco = () => {
	return (
		<div className="container h-100">
			<div className="row h-100">
				<div className="col-xs-12 col-xl-6 mt-xl-0 p-2 p-xl-5 d-flex justify-content-center justify-content-xl-start">
					<Formulario />
				</div>
				<div className="col-xs-12 col-xl-6 px-5 py-2 p-2 p-xl-5 d-flex flex-column align-items-center align-items-xl-end">
					<div className="d-flex h-100 flex-column">
						{/* componentizar */}

						<div className="text-center pt-4 text-light h-100">
							<div className="container h-100 fale-conosco-container">
								<div>
									<Logo style={{ width: 160, height: 'auto', marginBottom: 30 }} />
									<h5 className="font-weight-bold" style={{ marginBottom: 1, fontSize: 25 }}>
										Fale com a gente
									</h5>
									<a href={`mailto:${contatoMinhaSaude.email}`} className="text-light">
										{contatoMinhaSaude.email}
									</a>
								</div>
								<div style={{ marginTop: 10 }}>
									<h5 className="font-weight-bold" style={{ marginBottom: 1, fontSize: 25 }}>
										Whatsapp
									</h5>
									<a
										href={contatoMinhaSaude.whatsAppUrl}
										rel="noopener noreferrer"
										target="_blank"
										className="text-light"
									>
										{contatoMinhaSaude.whastApp}
									</a>
									<div>
										<p className="font-weight-bold d-inline-block horario-atendimento">
											Horário de atendimento:
										</p>{' '}
										<p className="font-weight-bold d-inline-block horario-atendimento">
											08:00 às 18:00
										</p>
									</div>
								</div>
								<div className="mb-4" style={{ marginTop: 10 }}>
									<DownloadAppButtons />
								</div>
								<div className="fale-conosco-termos-de-uso-container">
									<div style={{ marginBottom: 10 }}>
										<a
											style={{ textDecoration: 'underline' }}
											className="h5 font-weight-bold text-light text-uppercase"
											href={`${process.env.PUBLIC_URL}/termosDeUso.pdf`}
											target="_blank"
											rel="noopener noreferrer"
											download
											onClick={() =>
												ReactGA.event({
													category: 'Documentos',
													action: 'Download PDF',
													label: 'Termos de uso',
												})
											}
										>
											Termos de Uso
										</a>
										<p
											style={{ display: 'inline' }}
											className="h5 font-weight-bold text-light text-uppercase"
										>
											{'  '}E{'  '}
										</p>
										<a
											style={{ textDecoration: 'underline' }}
											className="h5 font-weight-bold text-light text-uppercase"
											href={`https://app.prontlife.com.br/prontlife-web/politica_privacidade_minhasaude.html`}
											target="_blank"
											rel="noopener noreferrer"
											>
											Política de Privacidade
										</a>
									</div>
								</div>
							</div>
						</div>
						{/* componentizar */}
					</div>
				</div>
			</div>
		</div>
	);
};
