import React, { useState } from 'react';
import Caneta from '../assets/stepBystep/caneta-orange.svg';
import './ComoAderir.scss';
import { DownloadAppButtonsMobile } from './DownloadAppButonsMobile';
import ModalFormAdesao from './ModalFormAdesao/ModalFormAdesao';

export const ComoAderir = () => {
	const [isOpenFormAdesao, setIsOpenFormAdesao] = useState(false);
	const openModal = () => setIsOpenFormAdesao(true);
	const closeModal = () => setIsOpenFormAdesao(false);

	return (
		<>
			<div className="bg-primary pt-5 pb-5">
				<div className="container d-flex flex-column flex-lg-row align-items-center justify-content-center">
					<div className="bg-light small-card-circle mr-1 mb-2 mb-lg-0 mr-lg-3">
						<img alt="Adesão" className="small-card-icon" src={Caneta} />
					</div>{' '}
					<h4 className="text-light font-weight-bold text-center m-0">
						Gestor Municipal,{' '}
						<ins className="font-weight-bold touchable" onClick={openModal}>
							faça aqui
						</ins>{' '}
						sua adesão!
					</h4>
				</div>
			</div>
			{/* <StepByStep /> */}

			<div className="my-5">
				<DownloadAppButtonsMobile web={true} />
			</div>
			{/* <SaibaMaisLinks /> */}
			<ModalFormAdesao isOpenFormAdesao={isOpenFormAdesao} closeModal={closeModal} />
		</>
	);
};
