import React, { useContext } from 'react';
import Balao from '../assets/carouselFeature/balao.svg';
import Carteirinha from '../assets/carouselFeature/carteirinha.svg';
import Empresa from '../assets/carouselFeature/empresa.svg';
import Exclamation from '../assets/carouselFeature/exclamation.svg';
import ThemeContext from '../context/themeContext';
import Image from '../models/Image';
import { Carousel } from './carousel/Carousel';
import { CarouselItem } from './carousel/ItemSingle';

function switchImage(
	index: number,
	imagesLight: Image[],
	imagesDark: Image[],
	isThemeDark: boolean,
	setSelectedImage: (value: Image) => void,
) {
	if (isThemeDark) {
		const selectedImage = imagesDark.find((image) => image.id === index);
		if (selectedImage) {
			setSelectedImage(selectedImage);
		}
	} else {
		const selectedImage = imagesLight.find((image) => image.id === index);
		if (selectedImage) {
			setSelectedImage(selectedImage);
		}
	}
}

export const CarouselFeatures = () => {
	const { imagesDark, imagesLight, isThemeDark, setSelectedImage } = useContext(ThemeContext);

	return (
		<Carousel
			settings={{
				autoplay: true,
				autoplaySpeed: 6000,
				afterChange: (index) => switchImage(index, imagesLight, imagesDark, isThemeDark, setSelectedImage),
			}}
			className=" pb-5"
		>
			<CarouselItem
				title="Sinais de Alarme"
				text="Indique seus sintomas, condições de saúde, alergia a medicamentos e monitore diariamente os sinais de alarme do Coronavírus."
				image={Exclamation}
			/>
			<CarouselItem
				title="Orientações de Saúde"
				text="Com o resultado da autoavaliação, saiba os canais e locais de atendimento adequados.  Tire suas dúvidas por telefone ou WhatsApp."
				image={Balao}
			/>
			<CarouselItem
				title="Cartão de Saúde"
				text="Tenha seu Cartão SUS e/ou Carteirinha de Saúde online para quando mais precisar."
				image={Carteirinha}
			/>
			<CarouselItem
				title="Empresas"
				text="Oriente seus colaboradores e funcionários a indicar suas condições de saúde."
				image={Empresa}
			/>
		</Carousel>
	);
};
