import { faCheckCircle as fasCheckCircle, faTimesCircle as fasTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Formik } from 'formik';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import Modal from 'react-modal';
import MaskedInput from 'react-text-mask';
//import "./Formulario.scss";
import * as Yup from 'yup';
import { phoneNumberMask, sanitizePhoneNumber, TELEMEDICINA_FORM } from '../../utils/strings';

export interface FormFields {
	nome: string;
	mensagem?: string;
	email?: string;
	telefone?: string;
	_formsubmit_id?: string;
}

const telemedicinaFormSchema = Yup.object().shape({
	nome: Yup.string().required('Nome obrigatório'),
	email: Yup.string()
		.email('E-mail inválido')
		.when('telefone', {
			is: (telefone) => !telefone || telefone.length === 0,
			then: Yup.string().required('Email ou Telefone obrigatório'),
		}),
	telefone: Yup.string().test('telefone', 'Telefone inválido', (value) => {
		const sanitized = sanitizePhoneNumber(value) || '';
		return !sanitized || sanitized.length >= 10;
	}),
	mensagem: Yup.string().required('Mensagem Obrigatória'),
});

export const TelemedicinaForm = () => {
	const onSubmit = async (values: FormFields) => {
		try {
			const formData = qs.stringify(values);
			setStatus('sending');
			await axios.post('https://formsubmit.io/send/e959f212-008f-4176-9250-c239cbb45ff9', formData, {
				maxRedirects: 0,
				validateStatus: function (status) {
					return status >= 200 && status <= 302;
				},
			});
			setStatus('success');
		} catch (error) {
			// TODO: Tratar resposta 302 do Axios como não erro.
			setStatus('success');
		}
	};

	// Para desabilitar botão de envio.
	const [sending, setSending] = useState(false);
	// Para definir resultado do modal.
	const [status, setStatus] = useState('initial');
	// Para controle do estado do modal.
	const [modalIsOpen, setIsOpen] = useState(false);
	// Para reiniciar o estado do formulário.
	const [resetter, setResetter] = useState<() => void>();

	const openModal = () => {
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
	};

	// Configuração inicial do modal.
	useEffect(() => Modal.setAppElement('#root'), []);

	// Trata o estado do envio.
	useEffect(() => {
		if (status && status !== 'initial') {
			if (status === 'sending') {
				setSending(true);
			} else {
				setSending(false);
			}
		}
	}, [status, sending]);

	// trata eventos do analytics e resetta o formulário
	useEffect(() => {
		if (status === 'success') {
			openModal();
			if (resetter) {
				// Limpa o formulário quando o envio é bem-sucedido.
				resetter();
			}

			ReactGA.event({
				category: 'fluxo',
				action: 'cadastro',
				label: 'successo',
			});
		} else if (status === 'error') {
			openModal();
			ReactGA.event({
				category: 'fluxo',
				action: 'cadastro',
				label: 'erro',
			});
		}
	}, [status, resetter]);

	// TODO: Generalizar campos em componentes.
	return (
		<>
			<Formik
				initialValues={{
					email: '',
					nome: '',
					mensagem: '',
					telefone: '',
					honeypot: '',
				}}
				validationSchema={telemedicinaFormSchema}
				onSubmit={(values, { resetForm }) => {
					setResetter(() => resetForm);
					const { email, nome, telefone, mensagem, honeypot } = values;
					onSubmit({
						nome,
						...(email ? { email } : {}),
						...(telefone ? { telefone } : {}),
						...(mensagem ? { mensagem } : {}),
						...(honeypot ? { _formsubmt_id: honeypot } : {}),
					});
				}}
			>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, dirty }) => (
					<form onSubmit={handleSubmit}>
						<div className="form-group text-left mb-1">
							<label htmlFor="input-nome">Nome</label>
							<input
								type="text"
								name="nome"
								className={`form-control ${
									errors.nome && touched.nome && errors.nome ? 'is-invalid' : ''
								}`}
								id="input-nome"
								aria-describedby="nome"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.nome}
							/>
						</div>

						<div className="form-group text-left mb-1">
							<label htmlFor="input-mail">E-mail</label>
							<input
								type="text"
								name="email"
								className={`form-control ${
									errors.email && touched.email && errors.email ? 'is-invalid' : ''
								}`}
								id="input-mail"
								aria-describedby="email"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.email}
							/>
						</div>

						<div className="form-group text-left mb-1">
							<label htmlFor="input-telefone">Telefone</label>
							<MaskedInput
								name="telefone"
								mask={phoneNumberMask}
								id="input-telefone"
								aria-describedby="telefone"
								className={`form-control ${
									errors.telefone && touched.telefone && errors.telefone ? 'is-invalid' : ''
								}`}
								placeholder="(99) 99999-9999"
								type="text"
								guide={false}
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.telefone}
							/>
						</div>

						<div className="form-group text-left mb-1">
							<label htmlFor="input-mensagem">Dúvida ou Sugestão</label>
							<textarea
								name="mensagem"
								className={`form-control ${
									errors.mensagem && touched.mensagem && errors.mensagem ? 'is-invalid' : ''
								}`}
								id="input-mensagem"
								aria-describedby="mensagem"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.mensagem}
							/>
						</div>

						<input
							style={{ display: 'none' }}
							type="text"
							name="honeypot"
							id="honeypot"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.honeypot}
						/>

						<div>
							<button
								className="btn btn-secondary text-light btn-lg font-weight-bold mt-4"
								style={{ minWidth: '75%' }}
								type="submit"
								disabled={sending}
							>
								Enviar
							</button>
						</div>
					</form>
				)}
			</Formik>

			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel="Cadastro"
				className="modal-dialog"
				closeTimeoutMS={500}
			>
				<div className="modal-content">
					<div className="modal-header">
						<h4 className="modal-title">
							{status === 'success'
								? TELEMEDICINA_FORM.TITLE.SUCCESS
								: status === 'error'
								? TELEMEDICINA_FORM.TITLE.ERROR
								: null}
						</h4>
						<button type="button" className="close" onClick={closeModal}>
							<span aria-hidden="true">&times;</span>
							<span className="sr-only">Fechar</span>
						</button>
					</div>
					<div className="modal-body">
						<div className="my-5 text-center">
							{status === 'success' ? (
								<FontAwesomeIcon color="#0cb7b7" icon={fasCheckCircle} size="5x" />
							) : status === 'error' ? (
								<FontAwesomeIcon color="#dc3545" icon={fasTimesCircle} size="5x" />
							) : null}
						</div>
						<p>
							{status === 'success'
								? TELEMEDICINA_FORM.BODY.SUCCESS
								: status === 'error'
								? TELEMEDICINA_FORM.BODY.ERROR
								: null}
						</p>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className={`btn ${status !== 'error' ? 'btn-primary' : 'btn-danger'}`}
							onClick={closeModal}
						>
							Fechar
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
};
