import React from 'react';
import './Card.scss';

export enum Side {
	LEFT = 'left',
	RIGHT = 'right',
}
export const Card = ({
	title,
	text,
	image,
	side,
	isBigImage,
}: {
	title: string;
	text: string;
	image: string;
	side: Side;
	isBigImage?: boolean;
}) => {
	const imageComp = (
		<div className="col-4 align-self-center">
			<img
				alt={title}
				src={image}
				className={'card-image'}
				style={{
					maxWidth: isBigImage ? '85%' : '100%',
					height: 'auto',
				}}
			/>
		</div>
	);
	const textComp = (
		<div className="col-8 d-flex flex-column justify-content-center">
			<h4 className="text-primary">{title}</h4>
			<p className="text-left text-muted">{text}</p>
		</div>
	);
	return (
		<div className="container">
			<div className="row card-row">
				{side === Side.LEFT ? imageComp : textComp}
				{side === Side.LEFT ? textComp : imageComp}
			</div>
		</div>
	);
};
