import React from 'react';
import { Parceiro } from '../../models/parceiro';
import CarouselParceiroItem from './CarouselParceiroItem';
import './ItemMultiple.scss';

export const CarouselItemMultiple = ({ parceiros }: { parceiros: Parceiro[][] }) => {
	return (
		<div className="container-fluid">
			{parceiros.map((paceiro, idx) => {
				return (
					<div key={`row${idx}`} className="row px-4">
						{paceiro.map(({ logo, link }, idx) => {
							return (
								<div
									key={`item${idx}`}
									className="col m-1 py-2 m-sm-2 m-lg-3 parceiro-block border-light"
								>
									<CarouselParceiroItem logo={logo} link={link} />
								</div>
							);
						})}
					</div>
				);
			})}
		</div>
	);
};
