import React from 'react';
import './ItemSingle.scss';

export const CarouselItem = ({
	title,
	subtitle,
	text,
	image,
	imageAltText,
}: {
	title: string;
	subtitle?: string;
	text: string;
	image: string;
	imageAltText?: string;
}) => {
	return (
		<div className="d-flex flex-column justify-content-center align-items-center">
			<div className="bg-primary card-circle">
				<img alt={imageAltText || ''} className="card-icon" src={image} />
			</div>
			<h1 className="text-primary mt-3">{title}</h1>
			{subtitle ? <h1 className="text-primary mb-2 text-center">{subtitle}</h1> : null}
			<p className="px-5 text-center">{text}</p>
		</div>
	);
};
