export const APPLE_URL = 'https://apps.apple.com/us/app/appminhasa%C3%BAde/id1506066737?ls=1';
export const ANDROID_URL = 'https://play.google.com/store/apps/details?id=com.prontlife.saude.minha';
export const TERMO_DE_CESSAO_DE_USO_URL = 'https://www.appminhasaude.com.br/AppMinhaSaude_TermoCessaoUso.docx';
export const TERMO_DE_ADESAO_URL = 'https://www.appminhasaude.com.br/AppMinhaSaude_TermoAdesao.docx';
export const PRONTLIFE_URL = 'https://prontlife.com.br/site';
export const LEMOBS_URL = 'https://lemobs.com.br';
export const COPPE_PARCERIA_URL =
	'https://coppe.ufrj.br/pt-br/planeta-coppe-noticias/noticias/startup-lanca-aplicativo-para-ajudar-municipios-no-combate-a-covid';
export const LNCC_PARCERIA_URL =
	'https://www.lncc.br/noticia/CORONAVIRUS_-_APLICATIVO_PERMITE_REALIZAR_TRIAGEM,_%20MONITORAR_SINTOMAS_E_ESTABELECER_CONTATO_%20DIRETO_ENTRE_O_CIDADAO_E_O_SERVICO_%20DE_SAUDE_DO_MUNICIPIO/1563';
export const UNIFEI_PARCERIA_URL =
	'https://unifei.edu.br/blog/unifei-e-parceira-em-projeto-de-aplicativo-lancado-para-monitoramento-de-informacoes-sobre-a-covid-19-e-da-saude-em-geral';
export const CNM_PARCERIA_URL = 'https://www.cnm.org.br/crises/noticiascrises/coronavirus/aplicativo-minha-saude';
export const API_CONTATO_HOMOLOG = 'https://api.homologa.appminhasaude.com.br/contato';
export const API_CONTATO_PROD = 'https://api.appminhasaude.com.br/contato';
