export const sanitizePhoneNumber = (value: string) => (value ? value.replace(/[^\d]/g, '') : null);

export const sanitizeCpf = (value: string) => (value ? value.replace(/[^\d]/g, '') : null);

export const phoneNumberMask = (value: string) => {
	const sanitized = sanitizePhoneNumber(value) || '';
	return sanitized.length < 11
		? ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
		: ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
};

export const cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];

export const TELEMEDICINA_FORM = {
	TITLE: {
		SUCCESS: 'Mensagem enviada!',
		ERROR: 'Ocorreu um erro!',
	},
	BODY: {
		SUCCESS: 'Entraremos em contato em breve.',
		ERROR: 'Ocorreu um erro na realização do cadastro, por favor tente novamente mais tarde.',
	},
};
