import React from 'react';
import ReactGA from 'react-ga';
import { TERMO_DE_CESSAO_DE_USO_URL } from '../../constants/constants';

interface LinkCessaoProps {
	text: string;
}
const LinkCessao = ({ text }: LinkCessaoProps) => (
	<a
		href={TERMO_DE_CESSAO_DE_USO_URL}
		target="_blank"
		rel="noopener noreferrer"
		className="lead"
		download
		onClick={() =>
			ReactGA.event({
				category: 'Documentos',
				action: 'Download PDF',
				label: 'Termo de cessão',
			})
		}
	>
		{text}
	</a>
);

export default LinkCessao;
