import React from 'react';
import { TelemedicinaForm } from './Form';
//import "./Formulario.scss";

export const Formulario = () => {
	return (
		<div className="d-flex flex-column h-100">
			<div className="text-light text-center flex-shrink-0 formulario" style={{ maxWidth: 450 }}>
				<h1 className="mb-3 mb-xl-5 font-weight-bold">Dúvidas e Sugestões</h1>
				<TelemedicinaForm />
			</div>
		</div>
	);
};
