import React from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './Carousel.scss';

const defaultSettings = {
	arrows: false,
	dots: true,
	infinite: true,
	swipeToSlide: true,
	slidesToShow: 1,
	className: 'center container',
	dotsClass: 'slick-dots slick-custom',
	customPaging: (i: any) => <div />,
};
export const Carousel = ({
	children,
	className,
	settings,
}: {
	children: JSX.Element | JSX.Element[];
	className?: string;
	settings?: Settings;
}) => {
	return (
		<div className={className}>
			<Slider {...defaultSettings} {...settings}>
				{children}
			</Slider>
		</div>
	);
};
