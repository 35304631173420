import { useField } from 'formik';
import React from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import './styles.scss';

interface InputTextFieldProps extends MaskedInputProps {
	name: string;
	label: string;
	isRequired?: boolean;
	dadosDoResponsavel?: boolean;
	firstSubmitForm: boolean;
}

const dadosDoResponsavelText = (
	<>
		<br /> <small className="text-muted">Dado do responsável pela gestão dos dados</small>
	</>
);
const InputMaskTextField = ({
	name,
	isRequired,
	label,
	dadosDoResponsavel,
	firstSubmitForm,
	...otherProps
}: InputTextFieldProps) => {
	const [field, meta] = useField(name);
	const { error, touched } = meta;
	const classNameRequired = isRequired ? 'form-required' : '';

	return (
		<div className="form-group text-left col mb-3">
			<label htmlFor={`input-${name}`}>
				<strong className={classNameRequired}>{label}</strong>
				{dadosDoResponsavel && dadosDoResponsavelText}
			</label>

			<MaskedInput
				{...field}
				{...otherProps}
				className={`form-control ${error && touched && firstSubmitForm ? 'is-invalid' : ''}`}
				id={`input-${name}`}
				aria-describedby={name}
			/>
		</div>
	);
};

export default InputMaskTextField;
