import React, { useState } from 'react';
import ReactGA from 'react-ga';
import {
	ANDROID_URL,
	APPLE_URL,
	CNM_PARCERIA_URL,
	COPPE_PARCERIA_URL,
	LEMOBS_URL,
	LNCC_PARCERIA_URL,
	PRONTLIFE_URL,
	TERMO_DE_ADESAO_URL,
	TERMO_DE_CESSAO_DE_USO_URL,
	UNIFEI_PARCERIA_URL,
} from '../../constants/constants';
import { MinhaSaudeAccordion } from '../accordion/Accordion';
import ModalFormAdesao from '../ModalFormAdesao/ModalFormAdesao';
import './DuvidasFrequentes.scss';

export const DuvidasFrequentes = () => {
	const [isOpenFormAdesao, setIsOpenFormAdesao] = useState(false);
	const openModal = () => setIsOpenFormAdesao(true);
	const closeModal = () => setIsOpenFormAdesao(false);

	const formAdesaoTouchable = (
		<span className="text-primary touchable" onClick={openModal}>
			<ins>Formulário de Adesão</ins>
		</span>
	);

	return (
		<div id={'duvidas-frequentes'} className="mt-5 mb-2 center">
			<h3 className="text-center  text-dark font-weight-bold mt-5 mb-3 title-aderir">Dúvidas frequentes</h3>
			<div className="justify-content-center">
				<div className="col-xs-12 col-md-6 mx-auto">
					<div className="duvida-frequente-accordion-container">
						<MinhaSaudeAccordion title={'Qual o objetivo do Aplicativo Minha Saúde?'}>
							<p className="duvidas-frequentes-text">
								Integrar dados de saúde do cidadão em um só lugar e identificar pessoas do grupo de
								risco e com sintomas do coronavírus através da Autoavaliação.
							</p>
						</MinhaSaudeAccordion>
					</div>
					<div className="duvida-frequente-accordion-container">
						<MinhaSaudeAccordion title={'Quem pode usar o Aplicativo?'}>
							<p className="duvidas-frequentes-text">
								Cidadãos, municípios, empresas e instituições não governamentais.
							</p>
						</MinhaSaudeAccordion>
					</div>
					<div className="duvida-frequente-accordion-container">
						<MinhaSaudeAccordion title={'Como o cidadão pode baixar o aplicativo?'}>
							<p className="duvidas-frequentes-text">
								Basta acessar pelo celular a{' '}
								<a href={ANDROID_URL} target="_blank" rel="noopener noreferrer">
									Google Play
								</a>{' '}
								ou a{' '}
								<a href={APPLE_URL} target="_blank" rel="noopener noreferrer">
									App Store
								</a>{' '}
								e buscar por "appminhasaude”, tudo junto. Em seguida, clicar em “Instalar” e aguardar o
								download.
							</p>
						</MinhaSaudeAccordion>
					</div>
					<div className="duvida-frequente-accordion-container">
						<MinhaSaudeAccordion title={'Como fazer a Autoavaliação pela primeira vez?'}>
							<p className="duvidas-frequentes-text">
								Após baixar o aplicativo, clique em "Começar" e avance na Autoavaliação até atingir o
								resultado. Após as orientações, clique em "Continuar" para realizar o Cadastro e obter
								todos os benefícios de integração de seus dados de saúde em um só lugar.
							</p>
						</MinhaSaudeAccordion>
					</div>

					<div className="duvida-frequente-accordion-container">
						<MinhaSaudeAccordion title={'Esqueci minha senha, e agora?'}>
							<p className="duvidas-frequentes-text">
								Ao iniciar o aplicativo, clique em "Já possui cadastro?" e, na tela seguinte, em
								"Esqueceu a senha?". Em seguida, você deve incluir o CPF (login) e clicar no botão
								Enviar SMS. Você receberá um código no celular e, na sequência dos passos poderá definir
								uma nova senha de acesso.
							</p>
						</MinhaSaudeAccordion>
					</div>
					<div className="duvida-frequente-accordion-container">
						<MinhaSaudeAccordion title={'Como alterar minha senha, minha foto e meus dados pessoais?'}>
							<p className="duvidas-frequentes-text">
								No canto superior esquerdo, há um círculo que deve ser clicado para acessar o Minha
								Conta, onde é possível alterar foto, incluir o Cartão do SUS, a Carteirinha de
								Vacinação, seus dados pessoais e a senha de acesso ao aplicativo. Além disso, é possível
								acessar pelo computador o link{' '}
								<a
									href={'https://appminhasaude.prontlife.com.br'}
									target="_blank"
									rel="noopener noreferrer"
								>
									https://appminhasaude.prontlife.com.br
								</a>{' '}
								e, com o mesmo login e senha, alterar seus dados.
							</p>
						</MinhaSaudeAccordion>
					</div>
					<div className="duvida-frequente-accordion-container">
						<MinhaSaudeAccordion
							title={'Como o Município pode aderir? Qual o benefício da adesão municipal?'}
						>
							<p className="duvidas-frequentes-text">
								A adesão é gratuita, basta o município assinar o{' '}
								<a
									href={TERMO_DE_CESSAO_DE_USO_URL}
									target="_blank"
									rel="noopener noreferrer"
									download
									onClick={() =>
										ReactGA.event({
											category: 'Documentos',
											action: 'Download PDF',
											label: 'Termo de cessão',
										})
									}
								>
									Termo de Cessão de Uso
								</a>
								,{' '}
								<a
									href={TERMO_DE_ADESAO_URL}
									target="_blank"
									rel="noopener noreferrer"
									download
									onClick={() =>
										ReactGA.event({
											category: 'Documentos',
											action: 'Download PDF',
											label: 'Termo de adesão',
										})
									}
								>
									Termo de Adesão
								</a>{' '}
								e preencher o {formAdesaoTouchable}. Em seguida, a Secretaria de Saúde poderá visualizar
								relatórios e análises de risco sobre a situação do coronavírus em sua cidade.
							</p>
						</MinhaSaudeAccordion>
					</div>
					<div className="duvida-frequente-accordion-container">
						<MinhaSaudeAccordion title={'Quem é o responsável pelo App Minha Saúde?'}>
							<p className="duvidas-frequentes-text">
								O aplicativo foi desenvolvido pela HealthTech{' '}
								<a href={PRONTLIFE_URL} target="_blank" rel="noopener noreferrer">
									ProntLife
								</a>{' '}
								e a GovTech{' '}
								<a href={LEMOBS_URL} target="_blank" rel="noopener noreferrer">
									Lemobs
								</a>{' '}
								e conta com o apoio dos parceiros{' '}
								<a href={COPPE_PARCERIA_URL} target="_blank" rel="noopener noreferrer">
									COPPE/UFRJ
								</a>
								,{' '}
								<a href={LNCC_PARCERIA_URL} target="_blank" rel="noopener noreferrer">
									LNCC
								</a>
								,{' '}
								<a href={UNIFEI_PARCERIA_URL} target="_blank" rel="noopener noreferrer">
									UNIFEI
								</a>{' '}
								e{' '}
								<a href={CNM_PARCERIA_URL} target="_blank" rel="noopener noreferrer">
									CNM
								</a>
								.
							</p>
						</MinhaSaudeAccordion>
					</div>
				</div>
			</div>
			<ModalFormAdesao isOpenFormAdesao={isOpenFormAdesao} closeModal={closeModal} />
		</div>
	);
};
