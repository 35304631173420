import { useField } from 'formik';
import React, { InputHTMLAttributes } from 'react';
import './styles.scss';

interface InputTextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
	name: string;
	label: string;
	isRequired?: boolean;
	limitSize?: number;
	firstSubmitForm: boolean;
}

const InputFileField = (props: InputTextFieldProps) => {
	const [field, meta, helpers] = useField(props.name);
	const { error } = meta;
	const { setValue } = helpers;

	const classNameRequired = props.isRequired ? 'form-required' : '';
	const limitSizeSpan = props.limitSize ? (
		<small className="text-muted">Tamanho máximo de arquivo: {props.limitSize}MB</small>
	) : null;
	return (
		<div className="form-group text-left col mb-3">
			<label className={classNameRequired} htmlFor={`input-${props.name}`}>
				<strong> {props.label}</strong>
			</label>
			<input
				style={{ height: 'calc(1.5em + 1.1rem + 2px)' }}
				name={field.name}
				accept=".pdf, .png, .jpg, .jpeg, .doc, .docx, .dot, .wps, .xml, .xps, .odt"
				type="file"
				className={`form-control  input-overflow-hidden ${error && props.firstSubmitForm ? 'is-invalid' : ''}`}
				id={`input-${props.name}`}
				aria-describedby={props.name}
				onChange={(event) => {
					if (event.currentTarget.files instanceof FileList) {
						setValue(event.currentTarget.files[0]);
					}
				}}
			/>
			{limitSizeSpan}
			<div className="invalid-feedback">{error}</div>
		</div>
	);
};

export default InputFileField;
