import React from 'react';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import ReactGA from 'react-ga';
import AppStore from '../../assets/app-store.png';
import PlayStore from '../../assets/google-play.png';
import { ANDROID_URL, APPLE_URL } from '../../constants/constants';
import './DownloadAppButtons.scss';

export const DownloadAppButtons = () => {
	return (
		<div className="flex-row">
			<div className="col-xs" style={{ textAlign: 'center' }}>
				
				{isAndroid || !isMobile ? (
				<a
					// target="_blank"
					rel="noopener noreferrer"
					//href={GOOGLE_URL}
					href={ANDROID_URL}
					download={true}
					onClick={() =>
						ReactGA.event({
							category: 'Store',
							action: 'Download',
							label: 'Google',
						})
					}
				>
					<img
						src={PlayStore}
						// style={{ marginTop: isMobile ? 0 : 1 }}
						alt="Faça o download na PlayStore"
						className="download-button  m-1"
					/>
				</a>
				 ) : null}
				 {isIOS || !isMobile ? (
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={APPLE_URL}
					// style={{ paddingBottom: 30 }}
					onClick={() =>
						ReactGA.event({
							category: 'Store',
							action: 'Download',
							label: 'Apple',
						})
					}
				>
					<img src={AppStore} alt="Faça o download na AppStore" className="download-button m-1" />
				</a>
				) : null}
			</div>
		</div>
	);
};
