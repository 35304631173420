import { useField } from 'formik';
import React, { InputHTMLAttributes } from 'react';
import { isMobile } from 'react-device-detect';
import './styles.scss';

interface InputTextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
	name: string;
	label: string;
	isRequired?: boolean;
	dadosDoResponsavel?: boolean;
	firstSubmitForm: boolean;
}

const dadosDoResponsavelText = (
	<>
		<br /> <small className="text-muted">Dado do responsável pela gestão dos dados</small>
	</>
);

const InputTextField = ({
	name,
	isRequired,
	label,
	firstSubmitForm,
	dadosDoResponsavel,
	...inputProps
}: InputTextFieldProps) => {
	const [field, meta] = useField(name);
	const { error, touched } = meta;
	const classNameRequired = isRequired ? 'form-required' : '';

	return (
		<div className="form-group text-left col mb-3">
			<label htmlFor={`input-${name}`}>
				<strong className={classNameRequired}>{label}</strong>
				{dadosDoResponsavel && dadosDoResponsavelText}
			</label>

			<input
				{...field}
				{...inputProps}
				onFocus={() => {
					if (isMobile) {
						window.scrollTo({ top: 20 });
					}
				}}
				className={`form-control ${error && touched && firstSubmitForm ? 'is-invalid' : ''}`}
				id={`input-${name}`}
				aria-describedby={name}
			/>
		</div>
	);
};

export default InputTextField;
