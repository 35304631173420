import React, { useState } from 'react';
import './Header.scss';
// import { DarkToggle } from "./header/DarkToggle";
import { DownloadAppButtons } from './header/DownloadAppButtons';
import { Headline } from './header/Headline';
import { Mockup } from './header/Mockup';
import ModalFormAdesao from './ModalFormAdesao/ModalFormAdesao';
import { HeaderCurve } from './shapes/HeaderCurve';

export const Header = ({ children }: { children?: JSX.Element }) => {
	const [isOpenFormAdesao, setIsOpenFormAdesao] = useState(false);
	const openModal = () => setIsOpenFormAdesao(true);
	const closeModal = () => setIsOpenFormAdesao(false);

	return (
		<>
			<div>
				<div className="logoAlpha bg-primary">
					<div className="row  mx-0">
						<div className="col-xs-12 col-lg-6 col-xl-5 px-0 my-lg-5">
							<Headline />
							<p className="text-light font-weight-bold ml-3 align-self-end link-text">
								Cidadão, baixe o Aplicativo Minha Saúde abaixo:
							</p>
							<DownloadAppButtons />
							<p className="text-light font-weight-bold ml-3 align-self-end link-text mt-4">
								Gestor Municipal,{' '}
								<ins className="font-weight-bold touchable" onClick={openModal}>
									faça aqui
								</ins>{' '}
								sua adesão!
							</p>
						</div>
						<div className="col-xs-12 col-lg-6 col-xl-7 px-0">
							<Mockup />
						</div>
					</div>
					<div style={{ height: 50 }} />
				</div>
				<div className="bg-light">
					<div className="curve-container">
						<HeaderCurve />
					</div>
					{children}
				</div>
			</div>
			<ModalFormAdesao isOpenFormAdesao={isOpenFormAdesao} closeModal={closeModal} />
		</>
	);
};
