import React from 'react';
import { Parceiro } from '../../models/parceiro';
import './ItemMultiple.scss';

const CarouselParceiroItem = ({ logo, link }: Parceiro) => {
	if (link) {
		return (
			<a href={link} target="_blank" rel="noopener noreferrer">
				<div
					style={{
						backgroundImage: 'url(' + logo + ')',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center',
						backgroundSize: 'contain',
					}}
					className="parceiro-item touchable"
				/>
			</a>
		);
	}

	return (
		<div
			style={{
				backgroundImage: 'url(' + logo + ')',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center',
				backgroundSize: 'contain',
			}}
			className="parceiro-item"
		/>
	);
};

export default CarouselParceiroItem;
