import React from 'react';
import Historico from '../assets/historico-saude.png';
import Exames from '../assets/laboratorio.png';
import Medidas from '../assets/medidas.png';
import { Card, Side } from './card/Card';

export const Cards = () => {
	return (
		<div>
			<Card
				title="Monitore suas Medidas"
				text="Com o aplicativo, você pode registrar e observar seus sinais vitais e seu médico pode analisá-los remotamente."
				image={Medidas}
				side={Side.LEFT}
			/>
			<Card
				title="Plantão e Locais de Atendimento"
				text="Fale com um profissional de plantão e veja no mapa as unidades de saúde mais próximas de você."
				image={Exames}
				side={Side.RIGHT}
			/>
			<Card
				title="Dados Integrados"
				text="Seus dados de saúde em um só lugar.  Atualize tipo sanguíneo, idade, contatos e deixe as equipes de saúde conhecerem você."
				image={Historico}
				side={Side.LEFT}
				isBigImage={true}
			/>
		</div>
	);
};
