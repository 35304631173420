import 'bootstrap';
import React from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { Router } from './components/router';
import { RouterContainer } from './components/RouterContainer';
import './custom.scss';

const App: React.FC = () => {
	ReactGA.initialize('UA-157357437-11');
	ReactGA.pageview(window.location.pathname + window.location.search);

	return (
		<BrowserRouter>
			<RouterContainer>
				<Router />
			</RouterContainer>
			<ToastContainer autoClose={3000} />
		</BrowserRouter>
	);
};

export default App;
