import React from 'react';
import './DuvidasFrequentesButton.scss';

export const DuvidasFrequentesButton = () => {
    return (<div className="mb-2 d-flex justify-content-center pb-5">
            <div>
                <a className="btn btn-primary btn-lg duvidas-frequentes-button" href="#duvidas-frequentes" role="button">Dúvidas Frequentes</a>
            </div>

    </div>);
}