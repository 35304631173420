import React from 'react';
import './Headline.scss';

export const Headline = () => {
	return (
		<div style={{ paddingBottom: 20 }} className="headline text-light">
			<h1>Autoavaliação Coronavírus</h1>
			<h2>
				Indique seus sintomas, temperatura e pressão. Saiba os locais de atendimento e entre em contato com os
				serviços de saúde de seu município.
			</h2>
		</div>
	);
};
