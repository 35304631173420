import React, { useState } from 'react';
import ThemeContext from '../../context/themeContext';
import { imagesDark, imagesLight } from '../../utils/mockups';
import { Cards } from '../Cards';
import { CarouselFeatures } from '../CarouselFeatures';
import { CarouselParceiros } from '../CarouselParceiros';
import { ComoAderir } from '../ComoAderir';
import { DownloadAppButtonsMobile } from '../DownloadAppButonsMobile';
import { DuvidasFrequentes } from '../duvidasFrequentes/DuvidasFrequentes';
import { DuvidasFrequentesButton } from '../DuvidasFrequentesButton';
import { Footer } from '../Footer';
import { Header } from '../Header';

export const Homepage = () => {
	const [selectedImage, setSelectedImage] = useState(imagesLight[0]);
	const [isThemeDark, setIsThemeDark] = useState(false);

	return (
		<>
			<ThemeContext.Provider
				value={{
					isThemeDark,
					imagesLight,
					imagesDark,
					selectedImage,
					setSelectedImage,
					setIsThemeDark,
				}}
			>
				<Header>
					<>
						<CarouselFeatures />
						<DuvidasFrequentesButton />
					</>
				</Header>
			</ThemeContext.Provider>
			<Cards />
			<div className="my-5">
				<DownloadAppButtonsMobile />
			</div>
			<CarouselParceiros />
			<ComoAderir />
			{/* <DownloadAppButtonsMobile /> */}
			<DuvidasFrequentes />
			<Footer />
		</>
	);
};
