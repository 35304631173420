import React from 'react';
export const HeaderCurve = () => {
	return (
		<svg
			viewBox="0 0 800 300"
			className="svg"
			preserveAspectRatio="none"
			style={{ maxHeight: 150, width: '100%', marginTop: -1 }}
		>
			<path id="curve" fill="#f0932b" d="M0,0 C24, 24, 337, 305, 800, 295 V0 Z" />
		</svg>
	);
};
