import React from 'react';
import { isMobile } from 'react-device-detect';
import { DownloadAppButtons } from './header/DownloadAppButtons';

export const DownloadAppButtonsMobile = ({ web = false }: { web?: boolean }) => {
	return isMobile || web ? (
		<div className="center text-align-center">
			<h4 className="text-dark font-weight-bold align-self-center download-app-button-title-text text-center">
				Cidadão, baixe o Aplicativo Minha Saúde abaixo:
			</h4>
			<DownloadAppButtons />
		</div>
	) : null;
};
