import React from 'react';
import ReactGA from 'react-ga';
import { TERMO_DE_ADESAO_URL } from '../../constants/constants';

interface LinkAdesaoProps {
	text: string;
}
const LinkAdesao = ({ text }: LinkAdesaoProps) => (
	<a
		href={TERMO_DE_ADESAO_URL}
		target="_blank"
		className="lead"
		rel="noopener noreferrer"
		download
		onClick={() =>
			ReactGA.event({
				category: 'Documentos',
				action: 'Download PDF',
				label: 'Termo de adesão',
			})
		}
	>
		{text}
	</a>
);

export default LinkAdesao;
