import React from 'react';
import { isMobile } from 'react-device-detect';
import { desenvolvimento, parceiros, parceirosMobile } from '../constants/parceiros';
import { Carousel } from './carousel/Carousel';
import { CarouselItemMultiple } from './carousel/ItemMultiple';

export const CarouselParceiros = () => {
	const parceirosSelected = isMobile ? parceirosMobile : parceiros;
	return (
		<>
			<h3 className="text-center text-dark font-weight-bold mb-4">Desenvolvimento</h3>
			<Carousel settings={{ autoplay: true, autoplaySpeed: 3000 }} className="mb-5">
				<CarouselItemMultiple parceiros={desenvolvimento} />
			</Carousel>
			<h3 className="text-center text-dark font-weight-bold mb-4">Parcerias e premiações</h3>
			<Carousel settings={{ autoplay: true, autoplaySpeed: 3000 }} className="mb-5">
				<CarouselItemMultiple parceiros={parceirosSelected} />
			</Carousel>
		</>
	);
};
