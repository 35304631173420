import AWS from '../assets/parceiros/aws.svg';
import BrazilLab from '../assets/parceiros/brazilLab.png';
import CNM from '../assets/parceiros/cnm.png';
import Coppe from '../assets/parceiros/coppe.svg';
import Finep from '../assets/parceiros/finep.svg';
import Inova from '../assets/parceiros/inova.png';
import Lemobs from '../assets/parceiros/lemobs.png';
import LNCC from '../assets/parceiros/lncc.svg';
import Petrobras from '../assets/parceiros/petrobras.png';
import Prontlife from '../assets/parceiros/prontlife.svg';
import Unifei from '../assets/parceiros/unifei.png';
import { Parceiro } from '../models/parceiro';

const LEMOBS_URL = 'https://lemobs.com.br/';
const PRONTLIFE_URL = 'https://prontlife.com.br/';
const COPPE_URL =
	'https://coppe.ufrj.br/pt-br/planeta-coppe-noticias/noticias/aplicativo-lancado-por-startup-da-coppe-comeca-ser-implantado-por';
const LNCC_URL =
	'https://www.lncc.br/noticia/Aplicativo_Minha_Saude__desenvolvido_em_parceria_com_LNCC__ja_e_usado_em_mais_de_400_cidades_no_combate_ao_CORONAVIRUS/1608';
const UNIFEI_URL =
	'https://unifei.edu.br/blog/aplicativo-minha-saude-que-tem-a-unifei-como-parceira-de-desenvolvimento-ja-e-usado-em-mais-de-400-municipios/';
const PETROBRAS_URL =
	'https://petrobras.com.br/fatos-e-dados/selecionamos-sete-empresas-em-edital-para-solucoes-de-rapida-implantacao.htm';
const FINEP_URL = 'http://www.finep.gov.br/chamadas-publicas/chamadapublica/642';
const CNM_URL = 'https://www.cnm.org.br/crises/noticiascrises/coronavirus/aplicativo-minha-saude';
const AMAZON_URL = 'https://aws.amazon.com/pt/';
const INOVACIDADE_URL = 'https://www.scbamerica.com/inovacidade';
const FORCA_URL_TAREFA_COVID_19_URL =
	'https://brazillab.org.br/noticias/conheca-as-9-startups-e-pmes-que-seguem-para-a-banca-pitch-do-2o-ciclo-da-forca-tarefa-covid-19';

export const desenvolvimento: Parceiro[][] = [
	[
		{ logo: Lemobs, link: LEMOBS_URL },
		{ logo: Prontlife, link: PRONTLIFE_URL },
	],

	[
		{ logo: Coppe, link: COPPE_URL },
		{ logo: LNCC, link: LNCC_URL },
		{ logo: Unifei, link: UNIFEI_URL },
	],
];

export const parceiros: Parceiro[][] = [
	[
		{ logo: Petrobras, link: PETROBRAS_URL },
		{ logo: Finep, link: FINEP_URL },
		{ logo: CNM, link: CNM_URL },
		{ logo: AWS, link: AMAZON_URL },
		{ logo: Inova, link: INOVACIDADE_URL },
		{ logo: BrazilLab, link: FORCA_URL_TAREFA_COVID_19_URL },
	],
];

export const parceirosMobile = [
	[
		{ logo: Petrobras, link: PETROBRAS_URL },
		{ logo: Finep, link: FINEP_URL },
		{ logo: CNM, link: CNM_URL },
	],
	[
		{ logo: AWS, link: AMAZON_URL },
		{ logo: Inova, link: INOVACIDADE_URL },
		{ logo: BrazilLab, link: FORCA_URL_TAREFA_COVID_19_URL },
	],
];
