export const WORDPRESS_URL = 'https://noticias.prontlife.app/wp-json/wp/v2/posts';

export interface Categoria {
	path: string;
	name: string;
	id: number;
}

export const categorias: Categoria[] = [
	{
		path: 'cidadao',
		name: 'Cidadão',
		id: 87,
	},
	{
		path: 'empresa',
		name: 'Empresa',
		id: 88,
	},
	{
		path: 'municipio',
		name: 'Município',
		id: 92,
	},
	{
		path: 'imprensa',
		name: 'Imprensa',
		id: 93,
	},
];
