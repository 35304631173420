import axios from 'axios';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { API_CONTATO_HOMOLOG, API_CONTATO_PROD } from '../../../../constants/constants';
import { convertSizeToMB } from '../../../../utils/file';
import { cpfMask, phoneNumberMask, sanitizeCpf, sanitizePhoneNumber } from '../../../../utils/strings';
import LinkAdesao from '../../../links/LinkAdesao';
import LinkCessao from '../../../links/LinkCessao';
import InputFileField from '../InputFileField.tsx/InputFileField';
import InputMaskTextField from '../InputMaskTextField/InputMaskTextField';
import InputTextField from '../InputTextField/InputTextField';
import './styles.scss';

export interface FormFields {
	email: string;
	nome_municipio_ou_consorcio: string;
	nome_responsavel: string;
	cpf: string;
	cargo: string;
	telefone: string;
	celular: string;
	_formsubmit_id: string;
	termo_adesao: File;
	termo_cessao: File;
}

interface InitialFormFields {
	email: string;
	nome_municipio_ou_consorcio: string;
	nome_responsavel: string;
	cpf: string;
	cargo: string;
	telefone: string;
	celular: string;
	honeypot: string;
	termo_adesao: File | null;
	termo_cessao: File | null;
}

interface RequestS3UrlResponse {
	baseDir: string;
	adesaoPost: S3SignedPostResponse;
	cessaoPost: S3SignedPostResponse;
}

interface S3SignedPostResponse {
	url: string;
	fields: S3SignedPostHeaders;
}

interface S3SignedPostHeaders {
	key: string;
	AWSAccessKeyId: string;
	['x-amz-security-token']: string;
	policy: string;
	signature: string;
}

const LIMIT_FILE_SIZE_MB = 10;

const initialValuesFormik: InitialFormFields = {
	email: '',
	nome_municipio_ou_consorcio: '',
	nome_responsavel: '',
	cpf: '',
	cargo: '',
	telefone: '',
	celular: '',
	honeypot: '',
	termo_adesao: null,
	termo_cessao: null,
};

const textLinkCessao = (
	<div className="form-group text-left col mb-3">
		<h3 className="h3">
			<strong>Termo de Cessão de Uso</strong>
		</h3>
		<p className="text-left lead">
			<LinkCessao text="Baixe Aqui" /> o Termo de Cessão de Uso e anexe uma via assinada ao final do formulário.
		</p>
	</div>
);

const textLinkAdesao = (
	<div className="form-group text-left col mb-3">
		<h3 className="h3">
			<strong>Termo de Adesão</strong>
		</h3>
		<p className="text-left lead">
			<LinkAdesao text="Baixe Aqui" /> o Termo de Adesão e anexe uma via assinada ao final do formulário.
		</p>
	</div>
);

const spinnerLoading = <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>;

const AdesaoFormSchema = Yup.object().shape({
	email: Yup.string().required('E-mail obrigatório').email('E-mail inválido'),
	nome_municipio_ou_consorcio: Yup.string().required('Nome do Município ou Consórcio é obrigatório'),
	nome_responsavel: Yup.string().required('Nome do responsável é obrigatório'),
	cargo: Yup.string().required('Cargo/Função é obrigatório'),
	cpf: Yup.string()
		.required('CPF é obrigatório')
		.test('CPF', 'CPF inválido', (value) => {
			const sanitized = sanitizeCpf(value) || '';
			return !sanitized || sanitized.length === 11;
		}),

	telefone: Yup.string()
		.required('Telefone obrigatório')
		.test('telefone', 'Telefone inválido', (value) => {
			const sanitized = sanitizePhoneNumber(value) || '';
			return !sanitized || sanitized.length >= 10;
		}),
	celular: Yup.string()
		.required('Celular obrigatório')
		.test('celular', 'Celular inválido', (value) => {
			const sanitized = sanitizePhoneNumber(value) || '';
			return !sanitized || sanitized.length >= 10;
		}),
	termo_adesao: Yup.mixed()
		.required('Termo de adesão obrigatório')
		.test('fileSize', `Arquivo excedeu tamanho máximo de ${LIMIT_FILE_SIZE_MB}MB`, (value: File | null) => {
			if (!value) return false;
			const fileSizeMB = convertSizeToMB(value.size);

			return value && fileSizeMB <= LIMIT_FILE_SIZE_MB;
		}),
	termo_cessao: Yup.mixed()
		.required('Termo de cessão obrigatório')
		.test('fileSize', `Arquivo excedeu tamanho máximo de ${LIMIT_FILE_SIZE_MB}MB`, (value: File | null) => {
			if (!value) return false;
			const fileSizeMB = convertSizeToMB(value.size);

			return value && fileSizeMB <= LIMIT_FILE_SIZE_MB;
		}),
});

const createDataToSend = async (values: FormFields) => {
	const {
		email,
		nome_municipio_ou_consorcio: nomeConsorcioMunicipio,
		nome_responsavel: nomeResponsavelGestaoSaude,
		cpf,
		cargo,
		telefone,
		celular,
		termo_adesao,
		termo_cessao,
	} = values;

	return {
		email,
		nomeConsorcioMunicipio,
		nomeResponsavelGestaoSaude,
		cpf,
		cargo,
		telefone,
		celular,
		nomeTermoAdesao: termo_adesao.name,
		nomeTermoCessaoUso: termo_cessao.name,
	};
};

const createS3UploadFormData = (file: File, headers: S3SignedPostHeaders) => {
	const formData = new FormData();

	formData.append('key', headers.key);
	formData.append('AWSAccessKeyId', headers.AWSAccessKeyId);
	formData.append('x-amz-security-token', headers['x-amz-security-token']);
	formData.append('policy', headers.policy);
	formData.append('signature', headers.signature);
	formData.append('file', file);
	//console.log(formData);

	return formData;
};

const uploadToS3 = async (file: File, S3PostResource: S3SignedPostResponse) => {
	try {
		//console.log(file, S3PostResource);
		await axios.post(S3PostResource.url, createS3UploadFormData(file, S3PostResource.fields), {
			maxRedirects: 0,
			validateStatus: function (status) {
				return status >= 200 && status <= 302;
			},
			timeout: 1000 * 60 * 5,
		});
	} catch (error) {
		throw new Error(`Erro ao dar upload no S3: ${error}`);
	}
};

const requestS3UploadURL = async () => {
	try {
		const { data } = await axios.get<RequestS3UrlResponse>(
			process.env.REACT_APP_BUILD_ENV === 'production'
				? `${API_CONTATO_PROD}/requestUrl`
				: `${API_CONTATO_HOMOLOG}/requestUrl`,
			{
				validateStatus: function (status) {
					return status >= 200 && status <= 302;
				},
				timeout: 5000,
			},
		);
		return data;
	} catch (error) {
		throw new Error(`Erro ao pedir URL para S3: ${error}`);
	}
};

const triggerEmail = async (values: FormFields, baseDir: string) => {
	try {
		let parameters = await createDataToSend(values);

		await axios.post(
			process.env.REACT_APP_BUILD_ENV === 'production'
				? `${API_CONTATO_PROD}/triggerEmail`
				: `${API_CONTATO_HOMOLOG}/triggerEmail`,
			{
				email: parameters.email,
				dirBucket: baseDir,
				nomeConsorcioMunicipio: parameters.nomeConsorcioMunicipio,
				nomeResponsavelGestaoSaude: parameters.nomeResponsavelGestaoSaude,
				cpf: parameters.cpf,
				cargo: parameters.cargo,
				telefone: parameters.telefone,
				celular: parameters.celular,
				nomeTermoAdesao: parameters.nomeTermoAdesao,
				nomeTermoCessaoUso: parameters.nomeTermoCessaoUso,
			},
		);
	} catch (error) {
		throw new Error(`Erro ao pedir envio do email: ${error}`);
	}
};

interface FormAdesaoProps {
	closeFormModal(): void;
}

const FormAdesao = ({ closeFormModal }: FormAdesaoProps) => {
	const onSubmit = async (values: FormFields) => {
		try {
			setSending(true);

			const { baseDir, adesaoPost, cessaoPost } = await requestS3UploadURL();
			/*
			const adesaoPromise = new Promise((resolve, reject) => {
				try {
					uploadToS3(values.termo_adesao, adesaoPost);
					resolve('OK');
				} catch (error) {
					reject(error);
				}
			});

			const cessaoPromise = new Promise((resolve, reject) => {
				try {
					uploadToS3(values.termo_cessao, cessaoPost);
					resolve('OK');
				} catch (error) {
					reject(error);
				}
			});*/
			const p1 = uploadToS3(values.termo_adesao, adesaoPost);
			const p2 = uploadToS3(values.termo_cessao, cessaoPost);

			await Promise.all([p1, p2]).catch((error) => {});

			await triggerEmail(values, baseDir);

			toast.success('Formulário enviado com sucesso!');

			ReactGA.event({
				category: 'fluxo',
				action: 'adesao',
				label: 'successo',
			});
			closeFormModal();
		} catch (error) {
			ReactGA.event({
				category: 'fluxo',
				action: 'adesao',
				label: 'erro',
			});

			if (error.response?.status === 415 || error.response?.status === 416) {
				toast.error('Formato do arquivo termo de cessão ou termo de adesão inválido');
			} else if (error.message && (error.message.includes(415) || error.message.includes(416))) {
				toast.error('Formato do arquivo termo de cessão ou termo de adesão inválido');
			} else if (error.response?.status === 413) {
				toast.error('Arquivo termo de cessão ou termo de cessão muito grande.');
			} else {
				toast.error('Erro ao enviar formulário, tente novamente!');
			}
		}

		setSending(false);
	};

	// Para desabilitar botão de envio.
	const [sending, setSending] = useState(false);
	// ativa validação após o primeiro submit falhar
	const [firstSubmitForm, setFirstSubmitForm] = useState(false);

	return (
		<div className="container mt-3 mb-4">
			<Formik
				initialValues={initialValuesFormik}
				validationSchema={AdesaoFormSchema}
				onSubmit={(values) => {
					const { honeypot, termo_adesao, termo_cessao, ...otherValues } = values;
					if (termo_adesao && termo_cessao) {
						onSubmit({ _formsubmit_id: honeypot, termo_cessao, termo_adesao, ...otherValues });
					}
				}}
			>
				{({ values, handleChange, handleBlur, handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						{textLinkAdesao}
						{textLinkCessao}
						<InputTextField
							name="nome_municipio_ou_consorcio"
							type="text"
							label="Nome do Município ou Consórcio Municipal"
							firstSubmitForm={firstSubmitForm}
							isRequired
						/>
						<InputTextField
							name="nome_responsavel"
							type="text"
							label="Nome do responsável pela gestão dos dados de saúde"
							firstSubmitForm={firstSubmitForm}
							isRequired
						/>
						<InputMaskTextField
							name="cpf"
							label="CPF"
							mask={cpfMask}
							type="tel"
							placeholder="000.000.000-00"
							guide={false}
							firstSubmitForm={firstSubmitForm}
							onFocus={() => {
								if (isMobile) {
									window.scrollTo({ top: 20 });
								}
							}}
							isRequired
							dadosDoResponsavel
						/>
						<InputTextField
							name="cargo"
							label="Cargo/Função"
							type="text"
							isRequired
							dadosDoResponsavel
							firstSubmitForm={firstSubmitForm}
						/>
						<InputTextField
							name="email"
							label="Endereço de e-mail"
							type="email"
							isRequired
							dadosDoResponsavel
							firstSubmitForm={firstSubmitForm}
						/>
						<InputMaskTextField
							name="telefone"
							label="Telefone"
							type="tel"
							mask={phoneNumberMask}
							placeholder="(99) 99999-9999"
							guide={false}
							isRequired
							dadosDoResponsavel
							firstSubmitForm={firstSubmitForm}
							onFocus={() => {
								if (isMobile) {
									window.scrollTo({ top: 20 });
								}
							}}
						/>
						<InputMaskTextField
							name="celular"
							label="Celular"
							type="tel"
							mask={phoneNumberMask}
							placeholder="(99) 99999-9999"
							guide={false}
							isRequired
							dadosDoResponsavel
							firstSubmitForm={firstSubmitForm}
							onFocus={() => {
								if (isMobile) {
									window.scrollTo({ top: 20 });
								}
							}}
						/>
						<InputFileField
							name="termo_adesao"
							label="Insira aqui o Termo de Adesão assinado"
							limitSize={LIMIT_FILE_SIZE_MB}
							isRequired
							firstSubmitForm={firstSubmitForm}
						/>
						<InputFileField
							name="termo_cessao"
							label="Insira aqui o Termo de Cessão assinado"
							limitSize={LIMIT_FILE_SIZE_MB}
							isRequired
							firstSubmitForm={firstSubmitForm}
						/>
						<input
							style={{ display: 'none' }}
							type="text"
							name="honeypot"
							id="honeypot"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.honeypot}
						/>
						<div className="text-center">
							<button
								className="btn btn-secondary  text-light btn-lg font-weight-bold mt-4"
								style={{ minWidth: '50%' }}
								type="submit"
								disabled={sending}
								onClick={() => setFirstSubmitForm(true)}
							>
								{sending ? spinnerLoading : 'Enviar'}
							</button>
						</div>
					</form>
				)}
			</Formik>
		</div>
	);
};

export default FormAdesao;
