import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ReactHtmlParser from 'react-html-parser';
import './Post.scss';

export const Post = ({
	image,
	imageHeight = 250,
	title,
	text,
	link,
	author,
	date,
}: {
	image?: string;
	imageHeight?: number;
	title: string;
	text: string;
	link?: { title: string; url: string };
	author?: string;
	date?: string;
}) => (
	<Card>
		{image ? (
			<div
				style={{
					width: 'auto',
					height: imageHeight,
					backgroundImage: 'url(' + image + ')',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
				}}
			/>
		) : null}
		<Card.Body>
			<Card.Title className="text-dark font-weight-bold">{title}</Card.Title>
			<Card.Text className="text-dark">{ReactHtmlParser(text)}</Card.Text>
			{link ? (
				<Button variant="primary" href={link.url} target="_blank" className="mt-3">
					{link.title}
				</Button>
			) : null}
		</Card.Body>
		{author || date ? (
			<Card.Footer>
				{author && date ? (
					<AuthorDate author={author} date={date} />
				) : author ? (
					<Author author={author} />
				) : date ? (
					<Date date={date} />
				) : null}
			</Card.Footer>
		) : null}
	</Card>
);

const AuthorDate = ({ author, date }: { author: string; date: string }) => (
	<small className="text-muted">
		Postado por <b>{author}</b> em <b>{date}</b>.
	</small>
);

const Author = ({ author }: { author: string }) => (
	<small className="text-muted">
		Postado por <b>{author}</b>.
	</small>
);

const Date = ({ date }: { date: string }) => (
	<small className="text-muted">
		Postado em <b>{date}</b>.
	</small>
);
