import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Card from 'react-bootstrap/Card';
import './Accordion.scss';

interface AccordionProps {
	title: string;
	children: JSX.Element;
}

export const MinhaSaudeAccordion = ({ title, children }: AccordionProps) => {
	return (
		<Accordion>
			<Card className="accordion-card-container">
				<AccordionHeader eventKey="0" title={title} />
				<Accordion.Collapse eventKey="0">
					<Card.Body>{children}</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
	);
};

export const AccordionHeader = ({ title, eventKey, callback }: { title: string; eventKey: string; callback?: any }) => {
	const [collapsed, setCollapsed] = useState(true);
	const decoratedOnClick = useAccordionToggle(eventKey, () => {
		callback && callback(eventKey);
		setCollapsed(!collapsed);
	});

	return (
		<Card.Header className="accordion-card-header" onClick={decoratedOnClick}>
			{title}
			<div className="accordion-card-header-icon">
				<FontAwesomeIcon color="#fff" icon={collapsed ? faCaretDown : faCaretUp} size="lg" />
			</div>
		</Card.Header>
	);
};
